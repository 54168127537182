import React from 'react';
import ContextProvider from '../components/ContextProvider';
import LegalStuff, { LegalStuffType } from '../components/LegalStuff';

const WebsiteTerms = ({ localeCode = 'en-US' }: { localeCode?: string }) => (
  <ContextProvider localeCode={localeCode}>
    <LegalStuff type={LegalStuffType.WEB_TERMS} />
  </ContextProvider>
);

export default WebsiteTerms;
